.projects {
  padding-left: 3rem;
  padding-right: 3rem;
}

.project__img img {
  border-radius: 10px;
}

.projects {
  &__header {
    font-size: 60px;
    font-style: italic;
    padding-bottom: 10px;
    padding-left: 30px;
  }
}

.project {
  &__header {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
    font-family: sans-serif;

    span {
      border-top: 1px solid #000;
      padding: 1rem;
      font-size: 30px;
      text-align: center;
    }
  }

  &__infos {
    margin-top: 1rem;

    &--name {
      position: relative;
      font-size: 3vw;
      font-family: sans-serif;
      display: flex;
      align-items: center;
      font-weight: 50px;
    }
  }

  &__img {
    position: relative;
    width: 100vw;
    height: 700px;

    img {
      width: 60vw;
      height: 600px;
      object-fit: cover;
      position: absolute;
      right: 10vw;
      top: 3rem;
    }
  }

  &__links {
    padding-top: 3rem;

    button {
      &:before {
        background: #000;
      }
      &:after {
        display: none;
      }
    }
  }
  &__tags {
    position: absolute;
    bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.coolFunnyLink {
  position: relative;
  font-family: sans-serif;

  svg {
    position: absolute;
    bottom: -5px;
    left: 0;
    stroke-width: 1px;
    width: 95px;

    path {
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
      transition: stroke-dashoffset 0.6s cubic-bezier(0.7, 0, 0.3, 1);
    }
  }
  &:hover {
    svg path {
      stroke-dashoffset: 0;
      transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
    }
  }
}

@media (max-width: 1350px) {
  .projects {
    padding: 3rem;
  }

  .project__img img {
    width: 74vw;
    right: 5rem;
    border-radius: 10px;
  }

  .project__infos--name {
    font-size: 38px;
  }

  .project .coolCircleEyeButton {
    transform: translate(-14rem, -3rem);
  }
}

@media (max-width: 830px) {
  .coolCircleEyeButton .textcircle {
    width: 240px;
  }

  .coolButton {
    margin-left: -4%;
  }
}

@media (max-width: 740px) {
  // .projects {
  //   padding: 1rem;
  // }

  // .project .coolCircleEyeButton .textcircle {
  //   width: 170px;
  //   animation: 10s linear infinite rotate;
  //   display: block;
  // }

  .coolCircleEyeButton text {
    font-weight: 500;
  }

  .eye {
    top: 40%;
  }

  .project__img img {
    margin-top: 2rem;
  }

  .project__infos--name {
    font-size: 35px;
    span {
      display: none;
    }
  }

  .project .coolCircleEyeButton {
    position: absolute;
    right: 0px;
    top: 0;
  }

  .coolCircleEyeButton .textcircle {
    width: 190px;
    margin-top: -25%;
  }

  .coolButton {
    margin-left: -6%;
  }
}

@media (max-width: 440px) {
  .project .coolCircleEyeButton {
    transform: translate(-5rem);
  }

  .coolCircleEyeButton .textcircle {
    width: 225px;
    margin-top: 10%;
    margin-left: 10%;
  }

  .eye {
    transform: translate(-13%, 7%);
  }

  .project__tags {
    gap: 6px;
    font-size: 14px;
  }

  .project__header span {
    font-size: 25px;
  }

  .project__tags {
    flex-direction: column;
    font-weight: bold;
    top: 0;
    left: 0;
  }

  .project__img img {
    margin-bottom: -5rem;
    width: 92vw;
    height: 100vw;
    right: 4rem;
    margin-top: 14rem;
    border-radius: 10px;
  }

  button.coolButton {
    margin-top: 25%;
    margin-left: -12%;
    z-index: 1;
  }

  // .coolButton {
  //   display: none;
  // }
}
