@import "https://fonts.googleapis.com/css2?family=Poppins&display=swap";
@import "https://fonts.googleapis.com/css2?family=League+Spartan&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins&display=swap";
@import "https://fonts.googleapis.com/css2?family=League+Spartan&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins&display=swap";
@import "https://fonts.googleapis.com/css2?family=League+Spartan&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
}

:after, :before {
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  font-family: League Spartan, Poppins, sans-serif;
  line-height: 1.5;
}

button, input, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

img, video, canvas, svg {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.section {
  min-height: 100vh;
  padding: 5rem 1rem 1rem;
  overflow: hidden;
}

@media (min-width: 650px) {
  .section {
    padding: 5rem;
  }
}

.section__header-title {
  color: #666;
  font-size: 17px;
  font-weight: 400;
}

.section__header-subtitle {
  font-size: 27px;
  font-weight: 700;
}

* {
  box-sizing: border-box;
  margin: 0;
}

:after, :before {
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  font-family: League Spartan, Poppins, sans-serif;
  line-height: 1.5;
}

button, input, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

img, video, canvas, svg {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.section {
  min-height: 100vh;
  padding: 5rem 1rem 1rem;
  overflow: hidden;
}

@media (min-width: 650px) {
  .section {
    padding: 5rem;
  }
}

.section__header-title {
  color: #666;
  font-size: 17px;
  font-weight: 400;
}

.section__header-subtitle {
  font-size: 27px;
  font-weight: 700;
}

.loading {
  height: 100vh;
  width: 100%;
  z-index: 98;
  background: #151515;
  place-items: center;
  font-family: Poppins, sans-serif;
  display: grid;
  position: absolute;
  inset: 0;
}

.loading__box {
  width: 500px;
  height: 200px;
  border: 3px solid #6cff8d7a;
  border-color: #6cff8d7a #6cff8d;
  position: relative;
}

@media (max-width: 600px) {
  .loading__box {
    width: 90vw;
  }
}

.loading__bar {
  width: 90%;
  height: 10px;
  background: #ccc;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.loading__bar--inner {
  height: 100%;
  width: 0;
  background: #6cff8d;
  border-radius: 2px;
}

.loading__text {
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  position: relative;
}

@media (max-width: 355px) {
  .loading__text {
    font-size: 18px;
  }
}

.loading__text--dot {
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  margin: 0 3px;
  animation: 1s infinite pulse;
}

@keyframes pulse {
  from {
    opacity: 0;
    background: #6cff8d;
  }

  to {
    opacity: 1;
  }
}

.loading__text--border {
  width: 85%;
  height: 1px;
  background: #6cff8c7a;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.loading__counter {
  color: #fff;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 70%;
  left: 0;
}

.loading__counter--number {
  color: #6cff8d;
}

.loading__width_animation {
  animation: 1s forwards width_animation;
}

@keyframes width_animation {
  0% {
    width: 85%;
  }

  100% {
    width: 280px;
  }
}

.loading__svg {
  width: 80%;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.landing {
  width: 100%;
  height: 100vh;
  color: #fff;
  background: #111 0 0 / cover no-repeat;
  position: relative;
  overflow: hidden;
}

.landing .header {
  z-index: 2;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
}

.landing .header__wrap {
  width: 60vw;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.landing .header__left {
  align-items: center;
  gap: 2rem;
  display: flex;
}

.landing .header__leftli {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .landing .header__wrap {
    width: 100vw;
  }
}

@media (max-width: 650px) {
  .landing .header__sm {
    display: none;
  }
}

@media (max-width: 370px) {
  .landing .header {
    left: 36%;
  }

  .landing .header__wrap {
    gap: 20%;
  }

  .landing .header .coolLink[data-text="Home"] {
    display: none;
  }
}

.landing .socials {
  z-index: 3;
  align-items: center;
  gap: 1rem;
  display: flex;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
}

.landing .scrollDown {
  z-index: 1;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
}

.landing .scrollDown__wheel {
  height: 42px;
  width: 24px;
  border: 2px solid #fff;
  border-radius: 10px;
}

.landing .scrollDown__wheel--inner {
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  margin-top: 5px;
  animation: .5s linear infinite movingwheel;
  position: absolute;
  left: 10px;
}

@keyframes movingwheel {
  0% {
    top: 1px;
  }

  25% {
    top: 2px;
  }

  50% {
    top: 3px;
  }

  75% {
    top: 2px;
  }

  100% {
    top: 1px;
  }
}

.landing .scrollDown .scrollDown__arrows {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.landing .scrollDown .scrollDown__arrows .scrollDown__arrow {
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  animation: 1s infinite alternate scrollAnim;
  transform: rotate(45deg);
}

.landing .scrollDown .scrollDown__arrows .scrollDown__arrow:nth-child(1) {
  animation-delay: .1s;
}

.landing .scrollDown .scrollDown__arrows .scrollDown__arrow:nth-child(2) {
  animation-delay: .2s;
}

.landing .scrollDown .scrollDown__arrows .scrollDown__arrow:nth-child(3) {
  animation-delay: .3s;
}

@keyframes scrollAnim {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.landing .intro {
  width: 100%;
  height: 60%;
  z-index: 2;
  color: inherit;
  font-family: League Spartan, sans-serif;
  position: absolute;
  top: 20%;
  left: 1rem;
}

.landing .intro h4 {
  font-size: 33px;
}

.landing .intro h1 {
  width: 93%;
  padding-top: 1rem;
  font-size: 35px;
}

@media (min-width: 600px) {
  .landing .intro h4 {
    font-size: 40px;
  }

  .landing .intro h1 {
    font-size: 55px;
  }
}

@media (min-width: 600px) and (min-width: 1200px) {
  .landing .intro {
    left: 5%;
  }

  .landing .intro h4 {
    font-size: 50px;
  }

  .landing .intro h1 {
    font-size: 55px;
  }
}

.landing canvas {
  position: absolute;
}

* {
  box-sizing: border-box;
  margin: 0;
}

:after, :before {
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  font-family: League Spartan, Poppins, sans-serif;
  line-height: 1.5;
}

button, input, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

img, video, canvas, svg {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.section {
  min-height: 100vh;
  padding: 5rem 1rem 1rem;
  overflow: hidden;
}

@media (min-width: 650px) {
  .section {
    padding: 5rem;
  }
}

.section__header-title {
  color: #666;
  font-size: 17px;
  font-weight: 400;
}

.section__header-subtitle {
  font-size: 27px;
  font-weight: 700;
}

.coolLink {
  place-items: center;
  display: grid;
  position: relative;
  overflow: hidden;
}

.coolLink:before {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 100%;
  background: currentColor;
  transition: transform .3s cubic-bezier(.5, .5, .3, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 1, 1);
}

.coolLink:after {
  content: attr(data-text);
  height: 100%;
  transition: transform .3s cubic-bezier(.5, .5, .3, 1);
  position: absolute;
  top: 0;
  transform: translate3d(150%, 0, 0);
}

.coolLink span {
  transition: transform .3s cubic-bezier(.5, .5, .3, 1);
}

.coolLink:hover:before {
  transform-origin: 0;
  transform: scale3d(1, 1, 1);
}

.coolLink:hover:after {
  transform: translate3d(0, 0, 0);
}

.coolLink:hover span {
  transform: translate3d(-150%, 0, 0);
}

.coolButton {
  cursor: pointer;
  width: 175px;
  height: 120px;
  color: #fff;
  background: none;
  border: none;
  position: relative;
}

.coolButton:before, .coolButton:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.coolButton:before {
  content: "";
  clip-path: path("M154.5, 88.5 C131,113.5 62.5, 110 30, 89.5 C-2.5, 69 -3.5, 42 4.5, 25.5 C12.5, 9 33.5, -6 85, 3.5 C136.5, 13 178, 63.5 154.5, 88.5 Z");
  background: #fff;
  transition: clip-path .5s cubic-bezier(.585, 2.5, .645, .55);
}

.coolButton:hover:before {
  clip-path: path("M143, 77 C117, 96 74, 100.5 45.5, 91.5 C17, 82.5 -10.5, 57 5.5, 31.5 C21.5, 6 79, -5.5 130.5, 4 C182, 13.5 169, 58 143, 77 Z");
}

.coolButton span {
  mix-blend-mode: difference;
  font-weight: 500;
}

.coolButton:after {
  content: "";
  height: 85%;
  width: 95%;
  border: 1px solid #fff;
  border-radius: 58% 42% 55% 45% / 56% 45% 55% 44%;
  transition: transform .5s cubic-bezier(.585, 2.5, .645, .55);
  top: 6px;
  transform: rotate(-20deg);
}

.coolButton:hover:after {
  transform: translate3d(0, -5px, 0);
}

@media (max-width: 800px) {
  .coolButton {
    font-size: 23px;
    transform: scale3d(.7, .7, .7);
  }
}

.coloredIcon {
  color: #fff;
  font-size: 35px;
}

.coloredIcon:hover {
  animation: .2s infinite coloredIconAnimation;
}

@keyframes coloredIconAnimation {
  0% {
    text-shadow: 5px 4px #f44336, -5px -6px #2196f3;
  }

  25% {
    text-shadow: -5px -6px #f44336, 5px 4px #2196f3;
  }

  50% {
    text-shadow: 5px -4px #f44336, -8px 4px #2196f3;
  }

  75% {
    text-shadow: -8px -4px #f44336, -5px -4px #2196f3;
  }

  100% {
    text-shadow: -5px 0 #f44336, 5px -4px #2196f3;
  }
}

.coolCircleEyeButton {
  display: inline-block;
  position: relative;
}

.coolCircleEyeButton .textcircle {
  width: 250px;
  animation: 10s linear infinite rotate;
  display: block;
}

.coolCircleEyeButton .textcircle text {
  text-transform: uppercase;
  fill: #000;
  font-size: 32px;
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}

.coolCircleEyeButton:hover text {
  font-weight: 500;
}

.coolCircleEyeButton:hover .eye__inner, .coolCircleEyeButton:hover .eye__iris, .coolCircleEyeButton:hover .eye__lashes-up {
  animation: 2s step-end infinite hide;
}

@keyframes hide {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }
}

.coolCircleEyeButton:hover .eye__lashes-down {
  animation: 2s step-end infinite show;
}

@keyframes show {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }
}

.eye {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.eye__outer, .eye__inner, .eye__lashes-up, .eye__lashes-down {
  stroke: #000;
  fill: none;
  stroke-width: 1.5px;
}

.eye__lashes-down {
  opacity: 0;
}

.projects {
  padding-left: 3rem;
  padding-right: 3rem;
}

.project__img img {
  border-radius: 10px;
}

.projects__header {
  padding-bottom: 10px;
  padding-left: 30px;
  font-size: 60px;
  font-style: italic;
}

.project__header {
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  font-family: sans-serif;
  display: grid;
}

.project__header span {
  text-align: center;
  border-top: 1px solid #000;
  padding: 1rem;
  font-size: 30px;
}

.project__infos {
  margin-top: 1rem;
}

.project__infos--name {
  font-family: sans-serif;
  font-size: 3vw;
  font-weight: 50px;
  align-items: center;
  display: flex;
  position: relative;
}

.project__img {
  width: 100vw;
  height: 700px;
  position: relative;
}

.project__img img {
  width: 60vw;
  height: 600px;
  object-fit: cover;
  position: absolute;
  top: 3rem;
  right: 10vw;
}

.project__links {
  padding-top: 3rem;
}

.project__links button:before {
  background: #000;
}

.project__links button:after {
  display: none;
}

.project__tags {
  flex-direction: column;
  gap: 1rem;
  display: flex;
  position: absolute;
  bottom: 1rem;
}

.coolFunnyLink {
  font-family: sans-serif;
  position: relative;
}

.coolFunnyLink svg {
  stroke-width: 1px;
  width: 95px;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.coolFunnyLink svg path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1px;
  transition: stroke-dashoffset .6s cubic-bezier(.7, 0, .3, 1);
}

.coolFunnyLink:hover svg path {
  stroke-dashoffset: 0;
  transition-timing-function: cubic-bezier(.8, 1, .7, 1);
}

@media (max-width: 1350px) {
  .projects {
    padding: 3rem;
  }

  .project__img img {
    width: 74vw;
    border-radius: 10px;
    right: 5rem;
  }

  .project__infos--name {
    font-size: 38px;
  }

  .project .coolCircleEyeButton {
    transform: translate(-14rem, -3rem);
  }
}

@media (max-width: 830px) {
  .coolCircleEyeButton .textcircle {
    width: 240px;
  }

  .coolButton {
    margin-left: -4%;
  }
}

@media (max-width: 740px) {
  .coolCircleEyeButton text {
    font-weight: 500;
  }

  .eye {
    top: 40%;
  }

  .project__img img {
    margin-top: 2rem;
  }

  .project__infos--name {
    font-size: 35px;
  }

  .project__infos--name span {
    display: none;
  }

  .project .coolCircleEyeButton {
    position: absolute;
    top: 0;
    right: 0;
  }

  .coolCircleEyeButton .textcircle {
    width: 190px;
    margin-top: -25%;
  }

  .coolButton {
    margin-left: -6%;
  }
}

@media (max-width: 440px) {
  .project .coolCircleEyeButton {
    transform: translate(-5rem);
  }

  .coolCircleEyeButton .textcircle {
    width: 225px;
    margin-top: 10%;
    margin-left: 10%;
  }

  .eye {
    transform: translate(-13%, 7%);
  }

  .project__tags {
    gap: 6px;
    font-size: 14px;
  }

  .project__header span {
    font-size: 25px;
  }

  .project__tags {
    flex-direction: column;
    font-weight: bold;
    top: 0;
    left: 0;
  }

  .project__img img {
    width: 92vw;
    height: 100vw;
    border-radius: 10px;
    margin-top: 14rem;
    margin-bottom: -5rem;
    right: 4rem;
  }

  button.coolButton {
    z-index: 1;
    margin-top: 25%;
    margin-left: -12%;
  }
}

.faq {
  background: linear-gradient(to right, #f3e5ce, #f4deb7, #f3e4ce);
  position: relative;
}

.faq__wrap {
  padding: 3rem 4.5%;
}

.faq .question {
  border-bottom: 1px solid #0f0f0f;
  padding: 24px 0;
  position: relative;
}

.faq .question h3 {
  cursor: pointer;
  color: #0f0f0f;
  font-size: 27px;
  font-weight: 600;
}

@media (max-width: 700px) {
  .faq .question h3 {
    font-size: 22px;
  }
}

.faq .question__wrap {
  grid-template-columns: 26px auto;
  align-items: center;
  gap: 1rem;
  display: grid;
}

.faq .question__answer {
  max-width: 40em;
  color: #0f0f0f;
  margin: 20px 0;
  padding-left: 40px;
  font-family: sans-serif;
  font-size: 20px;
  transition: all .2s ease-in-out;
  display: none;
}

.faq .question__status {
  height: 26px;
  width: 26px;
  background: none;
  border: 3px solid #0f0f0f;
  border-radius: 50%;
  margin-top: 2px;
  transition: all .2s ease-in-out;
}

@media (max-width: 700px) {
  .faq .question__status {
    width: 25px;
    height: 25px;
  }
}

.faq .question.open .question__answer {
  display: block;
}

.faq .question.open .question__status {
  outline-offset: 2px;
  background: #0f0f0f;
  outline: 1px solid #0f0f0f;
}

.contact {
  background: linear-gradient(to right, #f3e5ce, #f4deb7, #f3e4ce);
  font-family: sans-serif;
  position: relative;
}

.contact__header {
  margin-bottom: 3rem;
}

@media (max-width: 1100px) {
  .contact {
    padding: 2rem;
  }
}

.contact__profile {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.contact__profile-item {
  align-items: center;
  gap: 1rem;
  display: flex;
  position: relative;
}

.contact__profile-item img {
  width: 70px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
}

.contact__profile-col {
  z-index: 1;
  flex-direction: column;
  gap: 5px;
  font-size: 17px;
  display: flex;
}

.contact__profile-bg {
  width: 250px;
  height: 100px;
  background: #f6d5a4;
  border-radius: 5px;
  position: absolute;
  left: 20%;
}

.contact__socials {
  flex-wrap: wrap;
  gap: 3rem;
  padding-bottom: 3rem;
  font-size: 25px;
  display: flex;
}

.contact__footer {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 0;
}

.contact__footer-link {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

@media (max-width: 900px) {
  .contact__footer-link {
    font-size: 15px;
  }
}

.form__info {
  align-items: center;
  gap: 1rem;
  font-size: 2.2vw;
  font-weight: 500;
  display: flex;
}

.form__info input {
  font-size: inherit;
  font-weight: inherit;
  color: #444;
  background: none;
  border: none;
  border-bottom: 1px solid #000;
}

.form__info input:focus {
  outline: none;
}

.form__flex {
  gap: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.form__flex textarea {
  width: 70%;
  color: #444;
  resize: none;
  background: none;
  border: none;
  border-bottom: 1px solid #000;
  font-size: 2vw;
}

.form__flex textarea:focus {
  outline: none;
}

.form__flex .coolButton {
  font-size: 20px;
  font-weight: 900;
}

.form__flex .coolButton:before {
  background: #000;
}

.form__flex .coolButton:after {
  display: none;
}

.form__flex .coolButton:hover:before {
  background: #fff;
}

@media (max-width: 900px) {
  .form__flex {
    flex-direction: column;
  }

  .form__flex textarea, .form__info, .form__info textarea, .form__info input {
    font-size: 4vw;
  }
}

@media (max-width: 800px) {
  .contact .coolButton {
    font-size: 19px;
    transform: scale3d(1, 1, 1);
  }
}

@media (max-width: 610px) {
  .form__info {
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 5vw;
  }

  .form__info input {
    font-size: inherit;
    width: 100%;
  }

  .form__flex textarea {
    width: 100%;
    font-size: 5vw;
  }

  .contact .coolButton {
    margin-top: 5%;
    margin-left: 18%;
  }
}

.sektion {
  min-height: 80vh;
  margin: auto;
  padding: 2rem 0 4rem;
}

.section__title {
  font-size: var(--big-font-size);
  font-family: var(--gullible-font);
}

.section__subtitle {
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
  color: var(--text-color);
  display: block;
}

.section__title, .section__subtitle {
  text-align: center;
}

.skills__header {
  text-align: center;
  padding-bottom: 10px;
  padding-left: 30px;
  font-family: sans-serif;
  font-size: 60px;
  font-style: italic;
  font-weight: 600;
}

.skills__container-card {
  width: 15rem;
  height: auto;
  padding-bottom: var(--mb-0-75);
  margin: auto;
  margin-bottom: var(--mb-2);
  padding-bottom: 5rem;
  display: grid;
  position: relative;
}

.skills__container-card:hover {
  transition: all .3s;
  transform: scale(1.1);
}

.card_skill {
  width: 150px;
  height: 200px;
  text-shadow: 0 0 1em transparent(black, .5);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transform-style: preserve-3d;
  border-radius: 15px;
  margin: auto;
  padding: 2em;
  position: relative;
  overflow: hidden;
  transform: perspective(500px);
}

.card_skill:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--first-color-lighter);
  clip-path: circle(100px at 80% 20%);
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.card_skill:hover:before {
  clip-path: circle(280px at 80% -20%);
  transition: scale(1.08);
}

.card_skill:after {
  content: "";
  font-size: var(--h2-font-size);
  color: var(--text-color);
  font-style: italic;
  position: absolute;
  top: 30%;
  left: -20%;
}

.imgBx {
  width: 100px;
  height: 100px;
  object-fit: fill;
  transition: all .5s;
  position: absolute;
  transform: translate(5%, -10%);
}

.card_skill:hover .imgBx {
  width: 150px;
  height: 150px;
  transform: translate(-20%, -10%);
}

.contentBx {
  text-align: center;
  width: 0%;
  height: 0%;
  align-content: space-between;
  transition: all 1s;
  display: flex;
  position: relative;
  transform: scale(0);
}

.card_skill:hover .contentBx {
  height: 100%;
  width: 70%;
  object-fit: cover;
  text-align: center;
  transition: all .75s;
  display: flex;
  position: relative;
  transform: translate(-42%, 92%);
}

.cardskill .contentBx h2 {
  font-weight: var(--font-medium);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  position: relative;
}

.shadow2 {
  box-shadow: 0 2.8px 2.2px #00000009, 0 6.7px 5.3px #0000000c, 0 12.5px 10px #0000000f, 0 22.3px 17.9px #00000012, 0 41.8px 33.4px #00000016, 0 100px 80px #0000001f;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-left: auto;
  margin-right: auto;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 830px) {
  .skills__container-card {
    width: 10rem;
    height: auto;
    padding-bottom: var(--mb-0-75);
    margin: auto;
    margin-bottom: var(--mb-2);
    padding-bottom: 5rem;
    display: grid;
    position: relative;
  }
}

/*# sourceMappingURL=index.236fa45d.css.map */
