.faq {
  position: relative;
  background: linear-gradient(to right, #f3e5ce, #f4deb7, #f3e4ce);

  &__wrap {
    padding: 3rem 4.5%;
  }

  .question {
    position: relative;
    border-bottom: 1px solid #0f0f0f;
    padding: 24px 0 24px 0;

    h3 {
      cursor: pointer;
      font-size: 27px;
      color: #0f0f0f;
      font-weight: 600;

      @media (max-width: 700px) {
        font-size: 22px;
      }
    }

    &__wrap {
      display: grid;
      grid-template-columns: 26px auto;
      align-items: center;
      gap: 1rem;
    }

    &__answer {
      font-family: sans-serif;
      font-size: 20px;
      margin: 20px 0;
      max-width: 40em;
      color: #0f0f0f;
      padding-left: 40px;
      transition: all 0.2s ease-in-out;
      display: none;
    }

    &__status {
      height: 26px;
      width: 26px;
      background: transparent;
      border: 3px solid #0f0f0f;
      border-radius: 50%;
      margin-top: 2px;
      transition: all 0.2s ease-in-out;

      @media (max-width: 700px) {
        width: 25px;
        height: 25px;
      }
    }

    &.open .question__answer {
      display: block;
    }

    &.open .question__status {
      background: #0f0f0f;
      outline: 1px solid #0f0f0f;
      outline-offset: 2px;
    }
  }
}